<template>
  <div>
    <!-- 统计报表 -->
    <div class="content">
      <el-form inline>
        <el-form-item label="">
          <el-radio-group v-model="tagTable" @input="sjTypeChange">
            <el-radio :label="0">全部水井</el-radio>
            <el-radio :label="1">水井分组</el-radio>
          </el-radio-group>
          <span class="setting" @click="getSdsjSetting" v-if="tagTable == 1"> <span class="icon iconfont icon-shezhi"></span>设置水井 </span>
          <el-select v-if="tagTable == 1" style="margin-left: 24px" v-model="shuijingzuId" placeholder="请选择" @change="getSdsjStatistics">
            <el-option v-for="item in shuijingList" :key="item.id" :label="item.groupName" :value="item.id"> </el-option>
          </el-select>
          <el-button style="margin-left: 20px" size="small" type="primary" @click="exportAllExcel"
            >导出<span style="margin-left: 5px; display: inline-block" class="icon iconfont icon-daoruxiazai"></span
          ></el-button>
        </el-form-item>
      </el-form>
      <div>
        <div class="content-header">
          <div class="title">统计指标</div>
          <!-- <div class="time">
            <el-radio-group @input="changeTjTime" v-model="timeType" style="margin-bottom: 10px">
              <el-radio-button label="1">近1个月</el-radio-button>
              <el-radio-button label="2">近3个月</el-radio-button>
              <el-radio-button label="3">近半年</el-radio-button>
              <el-radio-button label="4">近一年</el-radio-button>
            </el-radio-group>
          </div> -->
          <div class="time">
            <el-date-picker
              v-model="timeValue"
              value-format="yyyy-MM-dd"
              type="daterange"
              :clearable="false"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="dataCheck"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="total-statistic-wrap" v-if="sdsjStatisticsData">
          <div class="show">
            <div class="li">
              <div class="value">{{ sdsjStatisticsData.deviceTotal || '-' }}</div>
              水井数目（口）
            </div>
            <div class="li">
              <div class="value">{{ sdsjStatisticsData.totalEle || '-' }}</div>
              使用电量（度）
            </div>
            <div class="li">
              <div class="value">{{ sdsjStatisticsData.totalWater || '-' }}</div>
              使用水量（方）
            </div>
            <div class="li">
              <div class="value">{{ sdsjStatisticsData.totalFee || '-' }}</div>
              使用金额（元）
            </div>
            <div class="li">
              <div class="value">-</div>
              用户数量（人）
            </div>
            <div class="li">
              <div class="value">-</div>
              充值金额（元）
            </div>
            <div class="li">
              <div>{{ sdsjStatisticsData.irrigatedArea || '-' }}</div>
              灌溉面积（亩）
            </div>
            <div class="li">
              <div class="value">{{ sdsjStatisticsData.waterPerMu || '-' }}</div>
              亩均用水量（方）
            </div>
            <div class="li">
              <div class="value">{{ sdsjStatisticsData.pricePerMu || '-' }}</div>
              亩均用金额（元）
            </div>
            <div class="li">
              <div class="value">-</div>
              累计使用时间（H）
            </div>
          </div>
        </div>
        <div class="sdsj-container">
          <div class="sd-wrap">
            <div class="content-header">
              <div class="title">累计水电量统计</div>
              <!-- <div class="time">
                <el-date-picker
                  v-model="timeValue"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  :clearable="false"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="dataCheck"
                >
                </el-date-picker>
              </div> -->
              <div class="time">
                <el-radio-group @input="changeTjTime" v-model="timeType" style="margin-bottom: 10px">
                  <el-radio-button label="1">近1个月</el-radio-button>
                  <el-radio-button label="2">近3个月</el-radio-button>
                  <el-radio-button label="3">近半年</el-radio-button>
                  <el-radio-button label="4">近一年</el-radio-button>
                </el-radio-group>
              </div>
            </div>
            <div class="clear">
              <div ref="chartContainer" id="chartContainer"></div>
            </div>
          </div>
          <div class="sj-wrap">
            <div class="content-header">
              <div class="title">近半年水电量统计</div>
            </div>
            <div class="clear">
              <div class="rig">
                <el-table :data="tableData" border style="width: 100%">
                  <el-table-column type="index" label="序号"> </el-table-column>
                  <el-table-column prop="anaylysisDate" label="月份">
                    <template slot-scope="scope">
                      {{ scope.row.anaylysisDate | formatMonth }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="costElectricity" label="累计电量使用(度)"> </el-table-column>
                  <el-table-column prop="costWater" label="累计水量使用(方)"> </el-table-column>
                  <el-table-column prop="costFee" label="累计使用金额 (元)"> </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button type="text" @click="tongjiDetail(scope.row)">查看</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="水井分组" class="dialogTable" :visible.sync="shuijingchuangjian" width="1100px" center style="font-weight: 500">
      <el-row style="margin-bottom: 20px">
        <el-button type="primary" @click="createFenzuName(false)">新建分组设置</el-button>
      </el-row>
      <el-table :data="shuijingList" border style="width: 100%">
        <el-table-column prop="groupName" label="分组名称"> </el-table-column>
        <el-table-column prop="updateTime" label="最近更新时间"> </el-table-column>
        <el-table-column prop="sjnum" label="水井个数"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div>
              <el-button type="text" @click="fenzuDetail(scope.row)">绑定水井</el-button>
              <el-button type="text" @click="createFenzuName(true, scope.row.groupName, scope.row.id)">修改</el-button>
              <!-- <el-button type="text" @click="fenzuDetail(scope.row)">删除</el-button> -->
              <el-popconfirm title="确认删除?" @confirm="fenzuDelete(scope.row)">
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
            </div>
          </template></el-table-column
        >
      </el-table>
    </el-dialog>
    <el-dialog title="详情" class="dialogTable" :visible.sync="tongjiDialog" width="1100px" center style="font-weight: 500">
      <el-row type="flex" justify="end" style="margin-bottom: 20px">
        <el-button type="primary" @click="exportExcel()">导出</el-button>
      </el-row>
      <el-table :data="tongjiList" border style="width: 100%" height="50vh">
        <el-table-column prop="deviceName" label="井号"> </el-table-column>
        <el-table-column prop="costElectricity" label="当月用电 (kw/h)"> </el-table-column>
        <el-table-column prop="costWater" label="当月用水量(m3)"> </el-table-column>
        <el-table-column prop="costFee" label="使用金额(元)"> </el-table-column>
        <el-table-column prop="groupName" label="组别"> </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="绑定设备" class="dialogTable" :visible.sync="selSjDg" width="1100px" center style="font-weight: 500">
      <div class="sj-content" v-if="sjList.length">
        <div class="sj-select-header">
          <div class="left">
            <div class="online-status online"></div>
            <span>在线</span>
            <div class="online-status"></div>
            <span>离线</span>
            <el-checkbox v-model="allChecked" @change="allSelSj()">全选</el-checkbox>
          </div>
          <!-- <div class="right"><span class="icon iconfont icon-zhushi"></span>用户可以自定义选择水井，自动生成统计数据</div> -->
        </div>
        <div class="list-wrap">
          <div class="sjitem" v-for="item in sjList" :key="item.id" @click="selSj(item)">
            <div class="left">
              <div :class="['online-status', item.onlineStatus == 0 ? 'online' : '']"></div>
              <span>{{ item.deviceName }}</span>
            </div>
            <div :class="['select-status', item.selected ? 'selected' : '']"></div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="foot-warp">
          <div class="tips">
            <span class="icon iconfont icon-zhushi"></span>
            重新选择后，上次数据会被覆盖，请慎重选择
          </div>
          <el-button
            @click="
              selSjDg = false;
              allChecked = false;
            "
            >取 消</el-button
          >
          <el-button type="primary" @click="confirmSjSelect">生成统计报表</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { sdsjList, sdsjStatistics, sdsjCostByDay, sdsjCostByMonth, sdsjSetting } from '@/api/hydropower/api.js';
import { postRequest, exportFile } from '@/api/api.js';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
export default {
  components: {},
  data() {
    return {
      allChecked: false,
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      tagTable: 0,
      tableData: [],
      sjType: 0, // 0:全部   1:自定义
      selSjDg: false,
      sjList: [],
      deviceIdList: [],
      reConfirmDg: false,
      tjStartTime: '',
      tjEndTime: '',
      timeType: 1, // 1:近1个月 2:近3个月 3:近半年 4:近一年
      timeValue: [],
      sdsjStatisticsData: null,
      shuijingList: [],
      shuijingzuId: '',
      shuijingchuangjian: false,
      shuijingSelectId: '',
      editSjInfo: {},
      tongjiDialog: false,
      tongjiList: [],
      currentTongjiItem: {}
    };
  },
  filters: {
    formatMonth(val) {
      return dayjs(val).format('YYYY-MM');
    }
  },
  created() {},
  mounted() {
    this.tjEndTime = dayjs().format('YYYY-MM-DD 00:00:00');
    this.tjStartTime = dayjs().subtract(1, 'month').format('YYYY-MM-DD 00:00:00');
    this.timeValue = [dayjs().subtract(1, 'week').format('YYYY-MM-DD  00:00:00'), this.tjEndTime];
    this.getSdsjStatistics();
    this.getFenzuList(true);
  },
  methods: {
    exportExcel() {
      exportFile('/api/iot/sdsj/sdsjDetailByMonth/export', {
        searchDate: this.currentTongjiItem.anaylysisDate,
        searchType: this.sjType,
        groupId: this.shuijingzuId
      });
    },
    exportAllExcel() {
      let params = {};
      // if (this.timeValue && this.timeValue.length) {
      //   params.startTime = this.timeValue[0]; //开始时间, 非必填
      //   params.endTime = this.timeValue[1]; //结束时间，非必填
      // }
      exportFile('/api/iot/sdsj/sdsjOptLog/Export', params);
    },
    tongjiDetail(item) {
      this.currentTongjiItem = item;

      postRequest('/api/iot/sdsj/sdsjDetailByMonth', {
        searchDate: item.anaylysisDate,
        searchType: this.sjType,
        groupId: this.shuijingzuId
      })
        .then((e) => {
          this.tongjiList = e;
          this.tongjiDialog = true;
        })
        .catch((error) => {});
    },
    sjTypeChange(val) {
      this.sjType = val;
      this.getSdsjStatistics();
    },
    // 报表统计
    getSdsjStatistics() {
      this.getsdsjCostByDay();
      this.getSdsjStatisticsInfo();
      this.getSdsjCostByMonth();
    },
    // 折线图
    getsdsjCostByDay() {
      let params = {
        searchType: this.sjType,
        groupId: this.shuijingzuId,
        startTime: this.tjStartTime,
        endTime: this.tjEndTime
      };

      sdsjCostByDay(params)
        .then((res) => {
          this.sdsjCostByDayData = res || [];
          this.getPicChart();
        })
        .catch((error) => {});
    },
    // 图表
    getPicChart() {
      let data1 = [];
      let data2 = [];
      let data3 = [];
      this.sdsjCostByDayData.forEach((v, i) => {
        data1.push(v.costWater);
        data2.push(v.costElectricity);
        data3.push(v.anaylysisDate);
      });
      this.picChart = this.picChart || echarts.init(this.$refs.chartContainer);
      this.picChart.setOption({
        title: {
          // text: '累计水电量统计'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['累计水量', '累计电量']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {}
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: data3,
          axisLabel: {
            rotate: 60,
            fontSize: 10
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '累计水量',
            type: 'line',
            //
            lineStyle: {
              color: '#88D2E1'
            },
            itemStyle: {
              color: '#88D2E1'
            },
            data: data1
          },
          {
            name: '累计电量',
            type: 'line',
            //
            lineStyle: {
              color: '#F9CAA5'
            },
            itemStyle: {
              color: '#F9CAA5'
            },
            data: data2
          }
        ]
      });
    },
    createFenzuName(isEdit = false, inputValue = '', groupId = '') {
      this.$prompt('请输入分组名称', '提示', {
        inputValue: inputValue,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          if (isEdit) {
            postRequest('/api/iot/sdsj/setting/group/update', { groupName: value, groupId })
              .then((res) => {
                this.$message({
                  type: 'success',
                  message: '修改成功: '
                });
                this.getFenzuList(false);
              })
              .catch((error) => {});
          } else {
            postRequest('/api/iot/sdsj/setting/group/add', { groupName: value })
              .then((res) => {
                this.$message({
                  type: 'success',
                  message: '创建成功: '
                });
                this.getFenzuList(false);
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {});
    },
    getSdsjCostByMonth() {
      let params = {
        searchType: this.sjType,
        groupId: this.shuijingzuId
      };
      sdsjCostByMonth(params)
        .then((res) => {
          this.tableData = res || [];
        })
        .catch((error) => {});
    },
    getFenzuList(isFirst = false) {
      postRequest('/api/iot/sdsj/setting/group/list', {})
        .then((res) => {
          this.shuijingList = res;
          if (isFirst) {
            this.shuijingzuId = res[0].id;
          }
        })
        .catch((error) => {});
    },
    fenzuDetail(item) {
      let params = {
        platId: 10,
        groupId: item.id
      };
      this.editSjInfo = item;
      sdsjList(params)
        .then((res) => {
          this.sjList = res || [];
          this.selSjDg = true;
          this.settingFlag = false;
          let allChecked = true;
          res.forEach((e) => {
            if (!e.selected) {
              allChecked = false;
            }
          });
          this.allChecked = allChecked;
        })
        .catch((err) => {
          this.settingFlag = false;
        });
      // this.shuijingSelectId = this.selSjDg = true;
    },
    fenzuDelete(item) {
      postRequest('/api/iot/sdsj/setting/group/delete', { groupId: item.id })
        .then((res) => {
          this.getFenzuList(true);
          if (item.id == this.shuijingzuId) {
            this.shuijingzuId = this.shuijingList[0].id;
            this.getSdsjStatistics();
          }
        })
        .catch((error) => {});
    },
    getSdsjSetting() {
      this.shuijingchuangjian = true;
      return;
      if (this.settingFlag) {
        return;
      }
      this.settingFlag = true;
      let params = {
        platId: 10
      };
      sdsjList(params)
        .then((res) => {
          this.sjList = res || [];
          this.selSjDg = true;
          this.settingFlag = false;
        })
        .catch((err) => {
          this.settingFlag = false;
        });
    },
    dataCheck() {
      if (this.timeValue.length) {
        (this.timeValue[0] = this.timeValue[0] + ' 00:00:00'), (this.timeValue[1] = this.timeValue[1] + ' 23:59:59');
      }

      this.getSdsjStatisticsInfo();
      // this.getsdsjCostByDay();
    },
    changeTjTime(val) {
      if (val == 1) {
        this.tjStartTime = dayjs().subtract(1, 'month').format('YYYY-MM-DD 00:00:00');
      } else if (val == 2) {
        this.tjStartTime = dayjs().subtract(3, 'month').format('YYYY-MM-DD 00:00:00');
      } else if (val == 3) {
        this.tjStartTime = dayjs().subtract(6, 'month').format('YYYY-MM-DD 00:00:00');
      } else if (val == 4) {
        this.tjStartTime = dayjs().subtract(12, 'month').format('YYYY-MM-DD 00:00:00');
      }
      // this.getSdsjStatisticsInfo();
      this.getsdsjCostByDay();
    },
    getSdsjStatisticsInfo() {
      let params = {
        searchType: this.sjType,
        // startTime: this.tjStartTime,
        // endTime: this.tjEndTime,
        groupId: this.shuijingzuId
      };
      if (this.timeValue.length) {
        params.startTime = this.timeValue[0];
        params.endTime = this.timeValue[1];
      }
      sdsjStatistics(params)
        .then((res) => {
          this.sdsjStatisticsData = res;
        })
        .catch((error) => {});
    },
    allSelSj() {
      let selArr = [];
      // let sjList = this.sjList.slice(0);
      this.sjList.forEach((sj) => {
        sj.selected = this.allChecked;

        if (sj.selected) {
          selArr.push(sj.id);
        }
      });
      this.selArr = selArr;
    },
    // 选择水井
    selSj(item) {
      let selArr = [];
      // let sjList = this.sjList.slice(0);
      this.sjList.forEach((sj) => {
        if (sj.id == item.id) {
          sj.selected = !sj.selected;
        }
        if (sj.selected) {
          selArr.push(sj.id);
        }
      });
      this.selArr = selArr;
    },
    // 确认选择的水井
    confirmSjSelect() {
      if (this.selArr.length) {
        let params = {
          deviceIdList: this.selArr,
          groupId: this.editSjInfo.id
        };
        sdsjSetting(params)
          .then((res) => {
            this.selSjDg = false;
            this.getFenzuList(false);
            this.shuijingzuId = this.editSjInfo.id;
            this.getSdsjStatistics();
            this.allChecked = false;
          })
          .catch((error) => {});
      }
    }
  }
};
</script>
<style scoped lang="scss">
// 统计报表
.content {
  margin: 0px 0 0 0;
  .setting {
    color: #409eff;
    font-size: 14px;
    margin-left: 20px;
    line-height: 20px;
    cursor: pointer;
    .icon {
      vertical-align: text-bottom;
      margin-right: 5px;
    }
  }
}
.content-header {
  border-bottom: 1px solid #ccc;
  padding: 0 0 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  .title {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 0px 0px;
    span {
      color: #3483ee;
    }
  }
}
.total-statistic-wrap {
  .show {
    width: 100%;
    padding: 10px 0 10px 0;
    text-align: center;
    color: #49af7b;
    display: flex;
    flex-wrap: wrap;
    .li {
      width: 185px;
      height: 60px;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      padding: 5px 0 0 0;
      margin: 0 10px 10px;
      text-align: center;
      border: 1px solid #ccc;
      line-height: 28px;
      .value {
        font-size: 20px;
        font-weight: bold;
        color: #000;
      }
    }
  }
}

// .time {
//   float: right;
// }

.sj-content {
  padding: 0 20px;
}
.sdsj-container {
  display: flex;
  .sd-wrap {
    width: 100%;
    margin-right: 40px;
  }
  .sj-wrap {
    width: 100%;
    .rig {
      width: 100%;
      margin: 10px 0 0 0;
    }
  }
}

#chartContainer {
  margin: 10px 40px 0 0;
  width: 100%;
  height: 300px;
  float: left;
}

.sj-content {
  padding: 0 20px;
  .sj-select-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 40px;
    padding-right: 10px;
    .left {
      display: flex;
      align-items: center;
      span {
        margin-right: 20px;
      }
    }
  }
  .list-wrap {
    display: flex;
    flex-wrap: wrap;
    height: 620px;
    overflow-y: scroll;
    .sjitem {
      width: 240px;
      height: 40px;
      background: #f7f8fa;
      border-radius: 8px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #313746;
      padding: 0 20px;
      margin-right: 10px;
      margin-bottom: 10px;
      box-sizing: border-box;
      justify-content: space-between;
      cursor: pointer;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .left {
        display: flex;
        align-items: center;
      }
      .icon {
        width: 18px;
        height: 18px;
        font-size: 18px;
        &.selected {
          color: #02b98f;
        }
      }
      .select-status {
        background: url(~@/images/circle_unchecked.png) center no-repeat;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        background-size: 100%;
        float: right;
        &.selected {
          background: url(~@/images/circle_checked.png) center no-repeat;
          background-size: 100%;
        }
      }
    }
  }
  .online-status {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: #7e88a8;
    margin-right: 4px;
    &.online {
      background: #02b98f;
    }
  }
}
.foot-warp {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .tips {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    position: absolute;
    left: 20px;
    .icon {
      width: 14px;
      height: 14px;
      color: #419eff;
      vertical-align: middle;
    }
  }
}
</style>
