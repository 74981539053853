<!-- //科技管理 -->
<template>
  <div>
    <div class="bottom12">
      <el-row :gutter="20">
        <el-col :span="4" class="flex-row">
          <span class="span">地块名称</span>
          <el-select style="flex: 1" clearable v-model="landId" placeholder="请选择地块名称" @change="landChange">
            <el-option v-for="item in plantLands" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4" class="flex-row">
          <span class="span">操作人</span>
          <el-select style="flex: 1" clearable v-model="userId" placeholder="请选择操作人" @change="userChange">
            <el-option v-for="item in sysUsers" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="6" class="flex-row">
          <span class="span">巡田时间</span>
          <el-date-picker
            style="flex: 1"
            v-model="searchDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-col>
        <el-col :span="2" class="flex-row">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="landName" label="地块名称"> </el-table-column>
      <el-table-column prop="landArea" label="地块面积"> </el-table-column>
      <el-table-column prop="farmingTypeName" label="巡田时间" width="200">
        <template slot-scope="scope"> {{ scope.row.realBeginDate | filterDate }}-{{ scope.row.realEndDate | filterDate }} </template>
      </el-table-column>
      <el-table-column prop="operatorName" label="操作人"> </el-table-column>
      <el-table-column label="巡田轨迹">
        <template slot-scope="scope">
          <el-button slot="reference" type="text" size="small" @click="toGuiji(scope.row)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column label="巡田类型">
        <template slot-scope="scope">
          {{
            scope.row.taskType == 'simpleTask'
              ? '普通巡田'
              : scope.row.taskType == 'schemeTask' || scope.row.taskType == 'temporaryTask'
              ? '任务巡田'
              : '-'
          }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="conclusionName" label="田情上报"> </el-table-column>
      <el-table-column prop="descriptions" show-overflow-tooltip label="备注"> </el-table-column>
      <el-table-column prop="name" label="影像">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.landImageUrl"
            style="width: 100px; height: 100px"
            :src="scope.row.landImageUrl"
            :zoom-rate="1.2"
            :preview-src-list="[scope.row.landImageUrl]"
            fit="cover"
          />
        </template>
      </el-table-column> -->
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <patrolDetail :detailId="detailId" :patrolVisible.sync="showDk"></patrolDetail>
  </div>
</template>

<script>
import { patrolList } from '@/api/api.js';

import dayjs from 'dayjs';
import patrolDetail from '@/components/patrolDetail';

export default {
  components: {
    patrolDetail
  },
  props: {
    sysUsers: [],
    plantLands: []
  },
  data() {
    return {
      detailId: '',
      dialogVisible: false,
      searchDate: [],
      userId: '',
      landId: '',
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      tableData: [],
      showDk: false
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  filters: {
    filterDate(val) {
      if (val) {
        return dayjs(val).format('YYYY.MM.DD');
      }
    }
  },
  methods: {
    toGuiji(item) {
      this.detailId = item.id;
      this.showDk = true;
    },
    getList(pageNum) {
      if (pageNum) {
        this.pageInfo.currentPage = pageNum;
      }
      patrolList({
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        ...this.searchParams
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },

    userChange(val) {
      this.userId = val;
    },
    landChange(val) {
      this.landId = val;
    },
    search() {
      this.searchParams = {
        farmingTypeId: this.farmingTypeId,
        ownerId: this.userId,
        landId: this.landId,
        startDate: this.searchDate && this.searchDate[0] ? dayjs(this.searchDate[0]).format('YYYY-MM-DD') : '',
        endDate: this.searchDate && this.searchDate[1] ? dayjs(this.searchDate[1]).format('YYYY-MM-DD') : ''
      };
      this.pageInfo.currentPage = 1;
      this.getList();
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span {
    font-size: inherit;
    font-size: 12px;
    width: 60px;
  }
}
.bottom12 {
  margin-bottom: 12px;
}
</style>
